import type { RouteLocationAsRelativeGeneric, RouteLocationAsPathGeneric, NavigationFailure } from 'vue-router';
import type { OperationResult } from '@urql/vue';

type NavigateToReturnType =
  | string
  | false
  | void
  | RouteLocationAsRelativeGeneric
  | RouteLocationAsPathGeneric
  | NavigationFailure;

/**
 * Handles the response from a GraphQL middleware operation.
 * If the response status is 401, it waits for the authExchange to redirect to login.
 * If there is an error, it logs the error to Sentry and navigates to a 500 error page.
 * If the entity does not exist, it navigates to a 404 error page.
 *
 * @param result - The result of the GraphQL operation.
 * @param id - The ID of the entity being fetched.
 * @param entity - The name of the entity being fetched.
 * @returns A navigation result or void.
 */
export const handleGraphqlMiddlewareResponse = async (
  result: OperationResult,
  id: string,
  entity: string,
): Promise<NavigateToReturnType> => {
  if (result.error) {
    if (result.error?.response?.status === 401) {
      await useDebounceFn(() => {}, 2000)();
      return;
    }

    sentryError(`Error fetching ${entity} #${id} in middleware`, result.error);
    return navigateTo('/500');

  } else if (!result.data?.[entity]?.id) {
    return navigateTo('/404');
  }
  return;
};
